<template>
	<div class="userManagement">
		<div class="userManagement-T">
			<div class="userManagementL">
				<Breadcrumb></Breadcrumb>
				<div class="RightsManagement-searchs">
					<div class="RightsManagement-searchs1">
						<inputs @inputs="inputValue" :inputShow="true" inputPlace="请输入名称"></inputs>
					</div>
					<div class="userManagementit-mai1-btn">
						<!-- <el-button type="danger" icon="el-icon-search">查询</el-button> -->
					</div>
				</div>
			</div>
			<div class="userManagementR">
				<div class="RightsManagement-searchsMai">
					<el-button type="primary" v-permission="'User:Add'" @click="clickNew()"
						icon="el-icon-plus">新增用户</el-button>
					<!-- <el-button type="danger" icon="el-icon-plus" @click="clickNew()">新增</el-button> -->
				</div>
			</div>
		</div>
		<div class="userManagement-B">
			<el-table :data="tableData" border style="width: 100%" size="small">
				<template slot="empty">
					<noData></noData>
				</template>
				<el-table-column prop="T_user" label="用户名" :show-overflow-tooltip="true">
				</el-table-column>
				<el-table-column label="权限" :show-overflow-tooltip="true">
					<template slot-scope="scope">
						{{ scope.row.T_power_name }}
					</template>
				</el-table-column>
				<el-table-column label="名称" :show-overflow-tooltip="true">
					<template slot-scope="scope">
						{{ scope.row.T_name }}
					</template>
				</el-table-column>
				<el-table-column prop="address" label="操作" :show-overflow-tooltip="true">
					<template slot-scope="scope">
						<el-button size="small" v-permission="'User:BindDevice'" @click="allpir('c', scope.row)">绑定</el-button>
						<el-button size="small" v-permission="'User:Edit'" @click="allpir('a', scope.row)">编辑</el-button>
						<el-button size="small" v-permission="'User:Del'" @click="allpir('b', scope.row)">删除</el-button>
					</template>
				</el-table-column>
			</el-table>
			<div style="margin-top: 50px;">
				<el-pagination @current-change="handleCurrentChange" background :current-page="pages.page"
					:page-size="pages.page_z" layout="total, prev, pager, next, jumper" :total="total">
				</el-pagination>
			</div>
		</div>
		<el-dialog :title="titles" :visible.sync="dialogVisible" :append-to-body="true">
			<div class="NewUser">
				<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="70px" class="demo-ruleForm">
					<el-form-item label="用户名" prop="T_user">
						<el-input v-model="ruleForm.T_user" :disabled="condition == 'add' ? false : true"
							placeholder="请输入用户名"></el-input>
					</el-form-item>
					<el-form-item label="密码" prop="T_pass">
						<el-input v-model="ruleForm.T_pass" show-password
							:placeholder="condition == 'add' ? '请输入密码' : '不填写则不修改密码'"></el-input>
					</el-form-item>
					<el-form-item label="名称" prop="T_name">
						<el-input v-model="ruleForm.T_name" placeholder="请输入名称"></el-input>
					</el-form-item>
					<el-form-item label="权限" prop="T_power">
						<el-select v-model="ruleForm.T_power" placeholder="请选择权限">
							<el-option v-for="item in options" :key="item.value" :label="item.label"
								:value="item.value"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item>
						<el-button type="primary" @click="submitForm('ruleForm')">{{ titles == '新增' ? '立即创建' : '立即编辑'
						}}</el-button>
						<el-button @click="dialogVisible = false">取消</el-button>
					</el-form-item>
				</el-form>
			</div>
		</el-dialog>
		<el-drawer title="绑定" :visible.sync="drawer" @close="closes" direction="rtl" :append-to-body="true" size="50%">
			<div style="padding:0 20px;height: 100%;">
				<el-tabs v-model="activeName" @tab-click="handleClick"
					style="height: 100%;display: flex;flex-direction: column;">
					<el-tab-pane label="已绑定传感器" name="first" style="height: 100%;overflow:hidden;">
						<div style="height: 100%;overflow:hidden;display: flex;flex-direction: column;">
							<div style="flex: 1;overflow-y: auto;">
								<el-table :data="tableData1" border style="width: 100%" size="small" min-height="100%">
									<template slot="empty">
										<noData></noData>
									</template>
									<el-table-column type="index" width="50" label="序号"></el-table-column>
									<el-table-column prop="T_devName" label="设备名称" :show-overflow-tooltip="true">
									</el-table-column>
									<el-table-column label="操作" :show-overflow-tooltip="true">
										<template slot-scope="scope">
											<el-button type="danger" icon="el-icon-delete" size="small"
												@click="setJiebing(scope.row)">解绑</el-button>
										</template>
									</el-table-column>
								</el-table>
							</div>
							<div style="min-height: 80px;margin-top: 10px;">
								<el-pagination
								@current-change="handleCurrentChanges"
								:current-page="pages3.page_z"
								:page-sizes="[100, 200, 300, 400]"
								:page-size="pages3.page_z"
								layout="total, prev, pager, next"
								:total="total3" style="padding-left: 0;">
								</el-pagination>
							</div>
						</div>
					</el-tab-pane>
					<el-tab-pane label="设备列表" name="second" style="height: 100%;">
						<div style="height: 100%;overflow:hidden;display: flex;flex-direction: column;">
							<el-form :inline="true" class="demo-form-inline">
								<el-form-item label="名称">
									<el-input v-model="T_name" placeholder="请输入名称"></el-input>
								</el-form-item>
								<el-form-item>
									<el-button type="primary" @click="search">查询</el-button>
								</el-form-item>
							</el-form>
							<div style="flex: 1;overflow-y: auto;">
								<el-table :data="tableData1" min-height="100%" border style="width: 100%" size="small">
									<template slot="empty">
										<noData></noData>
									</template>
									<el-table-column type="index" width="50" label="序号">
									</el-table-column>
									<el-table-column prop="T_devName" label="设备名称" :show-overflow-tooltip="true">
									</el-table-column>
									<el-table-column label="操作" :show-overflow-tooltip="true">
										<template slot="header" slot-scope="scope">
											<el-button type="primary" icon="el-icon-paperclip" size="small" @click="setbing('all')">一键绑定</el-button>
										</template>
										<template slot-scope="scope">
											<el-button type="primary" icon="el-icon-paperclip" size="small" @click="setbing(scope.row)">绑定</el-button>
										</template>
									</el-table-column>
								</el-table>
							</div>
							<div style="min-height: 80px;margin-top: 10px;">
								<el-pagination
								@current-change="handleCurrentChanges"
								:current-page="pages3.page_z"
								:page-sizes="[100, 200, 300, 400]"
								:page-size="pages3.page_z"
								layout="total, prev, pager, next"
								:total="total3" style="padding-left: 0;">
								</el-pagination>
							</div>
						</div>
						
					</el-tab-pane>
				</el-tabs>
			</div>
		</el-drawer>
	</div>
</template>

<script>
import {
	UserList,
	UserDel,//删除
	PowerList,//权限
	UserAdd,//添加
	UserEdit,//编辑
	userbingList,//已绑定
	userUnbingList,//未绑定
	UserBindDev,//绑定
	UserUnBindDev//解绑
} from '@/api/companyManagement/userManagement.js'
export default {
	data() {
		return {
			tableData1: [],
			activeName: 'first',
			drawer: false,
			total: 0,
			pages: {
				T_name: '',
				page: 1,
				page_z: 10
			},
			T_name:'',//绑定搜索
			pages3:{
				T_uuid:'',
				page: 1,
				page_z: 10
			},
			total3:0,
			options: [],
			condition: '',//区分点的时编辑Edit还是新增add
			tableData: [],
			titles: '',
			dialogVisible: false,
			ruleForm: {
				T_name: '',
				T_power: "",
				T_user: '',//用户名
				T_pass: '',//密码
			},
			rules: {
				T_user: [{
					required: true,
					message: '请填写用户名（不能填写中文）',
					pattern: /^[a-zA-Z0-9_]{0,}$/,
					trigger: 'blur'
				}],
				T_pass: [{
					required: true,
					message: '请填写密码（字母+数字，8-18位）',
					// pattern: /^(?:(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])).{8,18}$/,//大写+小写+数字
					pattern: /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d].{7,18}$/,//字母+数字
					trigger: 'blur'
				}],
				T_name: [{
					required: true,
					message: '请输入名称',
					trigger: 'change'
				}],
				T_power: [{
					required: true,
					message: '请选择权限',
					trigger: 'change'
				}],
			}
		}
	},
	mounted() {
		this.GetUserListApi()
	},
	methods: {

		handleClick(tab) {
			this.pages3.page = 1
			if(tab.name=='first'){
				this.GetuserbingListApi()
			}else{
				this.GetuserunbingListApi()
			}
		},
		closes() {
			// this.tableData1 = []
			// this.activeName = 'first'
		},
		
		handleCurrentChange(e) { //跳转页
			////console.log('跳转页', e)
			this.pages.page = e
			this.GetUserListApi()
		},
		GetPowerListApi() {//权限列表
			return new Promise(resolve => {
				PowerList({}).then(res => {
					resolve(res)
				})
			})
		},
		GetUserListApi() {//数据列表
			UserList(this.pages).then(res => {
				if (res.data.Code === 200) {
					////console.log('分页', res)
					this.total = res.data.Data.Num
					this.tableData = res.data.Data.Data
				}
			})
		},

		allpir(i, item) {
			// ////console.log(i,item)
			if (i === "a") { //编辑
				if (this.$refs.ruleForm) { //为了验证第一次触发表单验证，第二次打开还是触发
					this.$refs.ruleForm.resetFields();
				}
				this.rules.T_pass[0].required = false
				PowerList({}).then(res => {
					if (res.data.Code === 200) {
						var arr = []
						res.data.Data.Data.forEach(item => {
							arr.push({
								value: item.Id,
								label: item.T_name
							})
						})
						this.options = arr
					}
				})
				this.ruleForm.T_name = item.T_name
				this.ruleForm.T_power = item.T_power
				this.ruleForm.T_user = item.T_user
				this.ruleForm.T_pass = item.T_pass
				this.ruleForm.T_uuid = item.T_uuid

				this.dialogVisible = true
				this.titles = "编辑"
				this.condition = 'Edit'





			} else if (i === 'b') { //删除
				this.$confirm('此操作将执行删除, 是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning',
					center: true
				}).then(() => {
					UserDel({
						T_uuid: item.T_uuid
					}).then(res => {
						////console.log('删除', res)
						if (res.data.Code === 200) {
							this.$message.success('操作成功，已经删除')
							this.GetUserListApi()
						}
					})
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消删除'
					});
				});
			} else {//绑定
				this.drawer = true
				this.activeName = 'first'
				this.T_name = ''
				this.pages3.page = 1
				this.pages3.T_uuid = item.T_uuid 
				this.GetuserbingListApi()
			}
		},
		// 绑定
		setbing(e){

			let {T_uuid} = this.pages3
			let obj = {T_uuid:T_uuid}
			e=='all'?obj = {...obj,T_oneKey:1}:obj = {...obj,T_sn:e.T_sn}
			UserBindDev(obj).then(res=>{
				////console.log('绑定',res)
				if (res.data.Code==200){
					e=='all'?this.$message.success('一键绑定成功'):this.$message.success('绑定成功')
					this.pages3.page =1 ;
					this.GetuserunbingListApi()
				}
			})
		},
		//解绑
		setJiebing(e){
			let {T_uuid} = this.pages3;
			UserUnBindDev({
				T_uuid:T_uuid,
				T_sn:e.T_sn
			}).then(res=>{
				if (res.data.Code==200){
					this.$message.success('解绑成功')
					this.pages3.page =1 ;
					this.GetuserbingListApi()
				}
			})
		},
		//绑定分页
		handleCurrentChanges(val) {
			this.pages3.page = val
			////console.log('this.activeName',this.activeName)
			this.activeName =='first'?this.GetuserbingListApi():this.GetuserunbingListApi()
			
		},
		//已绑定列表
		GetuserbingListApi(){
			userbingList(this.pages3).then(res=>{
				////console.log('已绑定',res)
				if (res.data.Code==200) {
					this.tableData1 = res.data.Data.Data
					this.total3 = res.data.Data.Num
				}
			})
		},
		//未绑定列表
		GetuserunbingListApi(){
			userUnbingList({...this.pages3,T_name:this.T_name}).then(res=>{
				////console.log('已绑定',res)
				if (res.data.Code==200) {
					this.tableData1 = res.data.Data.Data
					this.total3 = res.data.Data.Num
				}
			})
		},
		search() {//搜索
			this.pages3.page = 1
			this.GetuserunbingListApi()
		},
		submitForm(formName) {
			////console.log('点击', this.condition)
			this.$refs[formName].validate((valid) => {
				if (valid) {
					if (this.condition == 'add') {//新增
						this.SetUserAddApi()
					} else {//编辑
						this.SetUserEditApi()
					}
				} else {
					////console.log('error submit!!');
					return false;
				}
			});
		},
		SetUserAddApi() {//添加
			var obj = { ...this.ruleForm }
			obj.T_pass = this.$md5(obj.T_pass)
			UserAdd(obj).then(res => {
				////console.log('编辑', res)
				if (res.data.Code === 200) {
					this.dialogVisible = false
					this.$message.success('操作成功，已完成添加用户')
					this.GetUserListApi()
				}
			})
		},
		SetUserEditApi() {//编辑
			var obj = { ...this.ruleForm }
			////console.log('编辑1', obj.T_pass)
			obj.T_pass = obj.T_pass == '' ? '' : this.$md5(obj.T_pass)
			////console.log('编辑2', obj)
			UserEdit(obj).then(res => {
				////console.log('编辑', res)
				if (res.data.Code === 200) {
					this.dialogVisible = false
					this.$message.success('操作成功，已完成编辑')
					this.GetUserListApi()
				}
			})
		},
		inputValue(e) {
			this.pages.T_name = e
			this.pages.page = 1
			this.GetUserListApi()
		},
		async clickNew() { //新增
			var that = this
			if (this.$refs.ruleForm) { //为了验证第一次触发表单验证，第二次打开还是触发
				this.$refs.ruleForm.resetFields();
			}
			this.rules.T_pass[0].required = true
			var res = await this.GetPowerListApi()
			////console.log('权限', res)
			if (res.data.Code === 200) {
				var arr = []
				if (res.data.Data.Data != null) {
					res.data.Data.Data.forEach(item => {
						arr.push({
							value: item.Id,
							label: item.T_name
						})
					})
				} else {
					arr = []
				}

				that.options = arr
				that.dialogVisible = true
				that.titles = "新增"
				this.condition = 'add'
			}
		},
	}
}
</script>

<style lang="scss">

.userManagement {
	.userManagement-T {
		display: flex;
		align-items: center;
		justify-content: space-between;
		background: rgba(#fff, 1);
		height: 70px;
		padding: 15px 10px;
		box-shadow: 5px 5px 10px -10px #000;

		.userManagementL {
			display: flex;
			align-items: center;

			.RightsManagement-searchs {
				display: flex;
				align-items: center;
				justify-content: center;
				margin-left: 20px;

				.userManagementit-mai1-btn {}

				.RightsManagement-searchs1 {
					display: flex;
					align-items: center;
					justify-content: center;
				}
			}
		}

		.RightsManagement-searchsMai {
			display: flex;
			align-items: center;
			cursor: pointer;
		}
	}

	.userManagement-B {
		background: rgba(#fff, 1);
		margin: 10px;
		padding: 20px 30px;
		
	}
}
</style>
