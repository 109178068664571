// 登录接口
import {
	axios
} from '@/utils/http.js'

const UserApi = {
	UserList: '/v3/User/List',//内部管理列表
	UserDel: '/v3/User/Del',//内部管理列表
	PowerList: '/v3/Power/List_All',//添加/修改用户 - 权限选择列表
	UserAdd: '/v3/User/Add',//添加
	UserEdit: '/v3/User/Edit',//编辑

	userbingList: '/v3/User/Bind_Device_List',//已绑定设备
	userUnbingList: '/v3/User/Unbind_Device_List',//未绑定设备
	UserBindDev: '/v3/User/Bind_Device',//绑定
	UserUnBindDev: '/v3/User/Unbind_Device',//解绑


}
//解绑
export function UserUnBindDev(data) {
	return axios({
		url: UserApi.UserUnBindDev,
		method: 'post',
		data:data
	})
}
//绑定
export function UserBindDev(data) {
	return axios({
		url: UserApi.UserBindDev,
		method: 'post',
		data:data
	})
}
//未绑定设备
export function userUnbingList(data) {
	return axios({
		url: UserApi.userUnbingList,
		method: 'post',
		data:data
	})
}
//已绑定设备
export function userbingList(data) {
	return axios({
		url: UserApi.userbingList,
		method: 'post',
		data:data
	})
}

export function UserEdit(data) {
	return axios({
		url: UserApi.UserEdit,
		method: 'post',
		data:data
	})
}
export function UserAdd(data) {
	return axios({
		url: UserApi.UserAdd,
		method: 'post',
		data:data
	})
}
//公司列表
export function PowerList(data) {
	return axios({
		url: UserApi.PowerList,
		method: 'post',
		data:data
	})
}
//公司列表
export function UserDel(data) {
	return axios({
		url: UserApi.UserDel,
		method: 'post',
		data:data
	})
}

//公司列表
export function UserList(data) {
	return axios({
		url: UserApi.UserList,
		method: 'post',
		data:data
	})
}